import { PageProps } from "gatsby";
import * as React from "react";
import Api from "src/api";
import Seo from "src/components/Seo";
import LessonRoute from "src/routes/School/Lesson";
import { Lesson } from "src/types";

interface Props extends PageProps {}
interface State {
  lesson?: Lesson;
  nextLesson?: Lesson;
}

class Lessons extends React.Component<Props> {
  public state: State = {
    lesson: null,
    nextLesson: null,
  };

  public async componentDidMount() {
    try {
      await this.getLessonPage();
    } catch (error) {
      // tslint:disable-next-line: no-unsafe-any
      console.error(error && error.message);
    }
  }

  public render() {
    const [courseId, lessonId]: string[] = this.props["*"].split("/");
    const lsn = this.state.lesson;
    if (!lsn) return null;
    return (
      <>
        <Seo
          pageTitle={lsn.name}
          pageDescription={(lsn.description || "").replace(/<(.|\n)*?>/g, "")}
        />
        <LessonRoute
          lesson={this.state.lesson}
          nextLesson={this.state.nextLesson}
          courseId={courseId}
          updatePage={this.getLessonPage}
          {...this.props}
        />
      </>
    );
  }

  public async componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      await this.getLessonPage();
      window.scroll({
        top: 0,
      });
    }
  }

  private getLessonPage = async () => {
    const [courseId, lessonId]: string[] = this.props["*"].split("/");
    const res = await Api.getLessonPage({ courseId, lessonId });
    this.setState({
      lesson: res.data.lesson,
      nextLesson: res.data.nextLessonPreview,
    });
  };
}

export default Lessons;
