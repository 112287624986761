import * as React from "react";
import styles from "./ColorBackground.module.css";

interface Props {}

const ColorBackground: React.SFC<Props> = (props) => {
  return (
    <section className={styles.aboutWrapper}>
      <div className={styles.BG} />
      <div className={styles.outerWrapper}>
        <div className={styles.content}>{props.children}</div>
      </div>
    </section>
  );
};

export default ColorBackground;
