import Vimeo from "@u-wave/react-vimeo";
import * as React from "react";
import ColorBackground from "src/components/ColorBackground";
import IconOnImage from "src/components/IconOnImage";
import PageHeader from "src/components/PageHeader";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
import Typography from "src/components/Typography";
import LessonAnnounce from "src/routes/School/LessonAnnounce";
import { Lesson as LessonType } from "src/types";
import chooseIcon from "src/utils/chooseLessonIcon";
import Homeworks from "./Homeworks";
import classes from "./Lesson.module.css";
import { Link } from "./Link";

interface Props {
  lesson: LessonType;
  nextLesson: LessonType;
  courseId: string;
  updatePage(): Promise<void>;
}

const Lesson: React.SFC<Props> = (props) => {
  const { lesson, nextLesson } = props;
  const { homework } = lesson;
  const icon = chooseIcon({
    hasHomework: !!lesson.lessonTask?.description,
    isWebinar: lesson.isWebinar,
  });

  return (
    <div style={{ color: "white" }}>
      <PageHeader text={lesson.name} />
      <div className={classes.margin}>
        {lesson.video && (
          <div className={classes.videoWrapper}>
            {/* tslint:disable-next-line: no-unsafe-any */}
            <Vimeo
              responsive
              video={lesson.video}
              className={classes.vimeoPlayer}
            />
          </div>
        )}
        {!lesson.video && (
          <div className={classes.imgWrapper}>
            {/* tslint:disable-next-line: no-unsafe-any */}
            <img src={lesson.img} />
            <IconOnImage icon={icon} big />
          </div>
        )}
      </div>
      <div>
        <Typography type="text">
          <div
            dangerouslySetInnerHTML={{
              __html: lesson.description,
            }}
          />
        </Typography>
      </div>
      <div className={classes.bigMargin}>
        {lesson.links?.map((curr, i) => (
          <Link {...curr} key={i} />
        ))}
      </div>
      <ColorBackground>
        <Homeworks
          description={lesson.lessonTask?.description}
          courseId={props.courseId}
          lessonId={lesson._id}
          hideButton={lesson.isLight}
          afterSubmit={props.updatePage}
          homeworks={[homework].filter((c) => !!c)}
          lessonOptions={[
            {
              value: lesson._id,
              label: lesson.name,
            },
          ]}
        />
        {lesson.content ? (
          <div>
            <SectionHeader header="Материалы урока" />
            <Typography type="text">
              <div
                dangerouslySetInnerHTML={{
                  __html: lesson.content,
                }}
              />
            </Typography>
          </div>
        ) : null}
      </ColorBackground>
      {nextLesson?._id ? (
        <div className={classes.margin}>
          <SectionHeader header="Следующий урок" />
          <div className={classes.nextLesson}>
            <LessonAnnounce
              announce={{
                img: nextLesson.img,
                name: nextLesson.name,
                announceDescription: nextLesson.announceDescription,
              }}
              link={`/school/lessons/${props.courseId}/${nextLesson._id}`}
              isWebinar={!!nextLesson.isWebinar}
              hasHomework={!!nextLesson.lessonTask?.description}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Lesson;
