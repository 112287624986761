import * as React from "react";
import linkIcon from "src/images/chain-link.svg";
import classes from "./Lesson.module.css";
import Typography from "src/components/Typography";

interface Props {
  link: string;
  name: string;
}

export const Link: React.SFC<Props> = (props) => {
  return (
    <div style={{ display: "flex" }} className={classes.linkWrapper}>
      <img src={linkIcon} alt="link icon" />
      <Typography type="text">
        <a href={props.link} target="blank">
          {props.name || props.link}
        </a>
      </Typography>
    </div>
  );
};
